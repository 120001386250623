import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Past - Present</h2>
          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <h3>Once upon a time</h3>
          <p>
            I cannot remember exactly, but my first coding experience was with
            the archaic language of LOGO in middle school. Or maybe it was
            BASIC. Later in 10th grade we began learning C++ and that's when I
            really got hooked to coding.
          </p>
          <h3>Coding felt cool</h3>
          <p>
            The computer lab at school quickly became my sanctuary. This
            happened not just because I was addicted to the 'aha' moments of
            coding. Playing around with code was certainly a lot of fun, but my
            affinity for this place was born out of something quite mundane.
            More than anything else, in the sweltering hot summers of winterless
            southern India, the computer lab was by far the coldest room in my
            school. In fact, most of the other rooms were not even air
            conditioned. It was an oasis.
          </p>
          <h3>College</h3>
          <p>
            With some credit going to that air conditioner, I decided to stay
            true to my passion for coding by majoring in Computer Science at
            UNC-Chapel Hill. Toward the end of sophomore year I started working
            part-time as an IT Specialist at The Disability Awareness Council in
            Chapel Hill. The rest of college consisted of numerous interesting
            classes where we did everything from using Python to analyze DNA
            sequences, to almost empathizing with a computer through Assembly
            language, to feeling like a hacker by simulating XSS, buffer
            overflow and SQL injection attacks.
          </p>
          <h3>Small Step Giant Leap</h3>
          <p>
            Working at The Disability Awareness Council (TDAC) was quite a small
            step toward my long-term goals, but a giant leap out of my comfort
            zone. There was not much coding involved. I had to interact with a
            lot of people and many of them were differently-abled. I did all
            sorts of tasks such as organizing physical and digital items, fixing
            workstations in the tech lab, troubleshooting remotely and on the
            phone, replacing faulty hardware like tablet screens, designing
            business cards and so on. Most of my tasks focused on improving
            accessibility to technology for both our staff and clients. My
            diverse experiences here contributed immensely to my personal
            growth. One time, I fixed and factory reset a donated computer, and
            a few days later I was helping a blind person use JAWS on that very
            same computer. It was a very fulfilling job and I ended up taking a
            full time position here after graduation.
          </p>
          <h3>The Entrepreneur</h3>
          <p>
            At TDAC, I also formed a great friendship with the executive
            director, Dr. Timothy Miles. He always urged me to stay true to the
            entrepreneurial spirit within me and granted me a lot of flexibility
            with my work hours. So during the first few months after graduation,
            I used my free time to work on a few entrepreneurial projects. I
            began brainstorming and creating app prototypes on Adobe XD. I
            started creating an e-commerce Print-On-Demand brand, and through
            this process I fell in love with using Adobe Illustrator and
            Photoshop to create designs and mockups. At the same time, I quickly
            and organically grew an Instagram art-sharing page to 26,000
            followers. I was developing an eye for viral content and learnt a
            lot about social media marketing. Despite plenty of failures along
            the way, I was adapting quickly and making consistent progress.
            However, something was eating me up inside... I was no longer coding
            as much.
          </p>
          <h3>To code or not to code</h3>
          <p>
            It felt like I was letting my true passion waste away. I thought
            deeply about it - should I go all in or should I recalibrate my
            focus? After some deliberation, I decided to pause my
            entrepreneurial projects. I had put many hours into them, but I knew
            I could create something more useful and valuable using code. I knew
            that success through code would be far more satisfying to me than
            success through anything else. I was excited.
          </p>
          <h3>A new chapter</h3>
          <p>
            My coding frequency had only decreased for a few months, so my
            skills were not too rusty. I have been learning new skills such as
            the MERN stack and improving considerably by coding for a few online
            courses and personal projects. And this brings us to the present. I
            am now actively pursuing a software or web developement job.
          </p>

          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Goals</h2>
          <h3>My Ideal Job</h3>
          <p>
            A challenging software or web development job at any organization
            who's primary objective is to create products or services that
            either contribute to human progress or help decrease human
            suffering.
          </p>
          <h3>Things that excite me:</h3>
          <ul>
            <li>Anything related to Javascipt.</li>
            <li>Anything that involves art, like UI design.</li>
            <li>Anything that requires me to learn completely new skills.</li>
          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>

          <h3>1. Asteroid </h3>
          <p>
            Connecting with strangers on most existing chat services like Omegle
            or OmeTV is a hit or miss. Asteroid takes some steps toward solving
            this problem by combining a few unique features with a simple UI...
            <p>
              <a
                style={{
                  fontWeight: 'normal',
                  color: 'pink',
                  paddingTop: '1.5%',
                }}
                href="https://www.github.com/log-one/asteroid"
                target="_blank"
              >
                View screenshots and read the rest on Github!
              </a>
            </p>
          </p>

          <h3>2. Rocket League Clip Comparer</h3>
          <p>
            My friend and I built this as the final project for our web
            development class in college and we received an 'A' grade. We used
            HTML, CSS, Javascript, PHP and a MySQL database handled using
            phpMyAdmin. The application pulled two short random clips of the
            popular video game Rocket League from a database of GIFs and allowed
            users to vote and comment on which clip they liked better. A
            leaderboard displayed the most popular clips.
            <p style={{ fontStyle: 'italic', paddingTop: '1.5%' }}>
              <span style={{ fontWeight: 'bold' }}>Note: </span>Unfortunately, I
              no longer have the source code for this project due to an
              accident. We did not use GitHub and it was on a flash drive. I
              have learnt from that mistake and have been extremely diligent
              about backing up all my data since then.
            </p>
          </p>

          <h3>3. This website</h3>
          <p>
            I will be upgrading and making this website more interesting, with
            the goal of making it my little base on the internet. So far it was
            built using Gatsby.js and styled with SASS.
          </p>

          <h3>New projects coming soon... </h3>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://github.com/log-one" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
