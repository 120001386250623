import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="inner">
      <h1>Hi, I'm Vishal.</h1>
    </div>
    <nav>
      <ul>
        <li>
          <button
            style={{ backgroundColor: 'rgba(0,0,0,0.65)' }}
            onClick={() => {
              window.scrollTo(0, 0)
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            style={{ backgroundColor: 'rgba(0,0,0,0.65)' }}
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Goals
          </button>
        </li>
        <li>
          <button
            style={{ backgroundColor: 'rgba(0,0,0,0.65)' }}
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            style={{ backgroundColor: 'rgba(0,0,0,0.65)' }}
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
